  <template>
  <b-card-code title="Remove Asset Mapping">
    <validation-observer ref="GroupAssetsAddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="addAssets">
        <!-- users -->
        <b-form-group label="Add Existing Users" label-for="users">
          <v-select
            v-model="user"
            label="text"
            placeholder="--Select User--"
            :options="users"
            @search="fetchUsers"
            autocomplete
            :reduce="(user) => user.value"
          />
        </b-form-group>
        <!-- {{ user }} -->
        <!-- Assets-->
        <b-form-group label="Add Existing Assets" label-for="Assets">
          <validation-provider
            #default="{ errors }"
            name="assets"
            rules="required"
          >
            <v-select
              v-model="assets"
              multiple
              label="text"
              :options="asset_options"
              @search="fetchOptions"
              autocomplete
              :reduce="(assets) => assets.value"
              state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- {{assets}} -->
        </b-form-group>
        <b-button type="submit" variant="primary" :disabled="invalid" size="sm">
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Remove</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { integer } from "vee-validate/dist/rules";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
    BSpinner,
  },
  props: {
    org_id: {
      type: Number,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
  },
  watch: {
    // sidebar_title() {
    //   this.fetchOptions();
    // },
  },
  //   mounted() {
  //     this.fetchUsers();
  //   },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      assets: [],
      asset_options: [],
      page_title: "Add Assets",
      asset_file: null,
      asset_type: "",
      isloading: false,
      user: null,
      users: [{ value: null, text: "--Select User--" }],
      // group_owner_id: this.props.group_owner_id
    };
  },
  methods: {
    addAssets: function () {
      this.$refs.GroupAssetsAddForm.validate().then((success) => {
        this.isloading = true;
        let data = {
          user_id: this.user,
          assets: this.assets,
        };
        const a_options = {
          method: "POST",
          data: data,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "asset/remove-assigned-assets/",
        };
        var self = this;
        this.$http(a_options).then((res) => {
          if (res.data.status == "success") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.isloading = false;
            this.assets = [];
            this.closeSidebar();
          }
        });
      });
    },

    //----------- fetch data

    fetchOptions(search) {
      if (search.length > 2) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset?org_id=" +
            this.org_id +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          //   self.asset_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].asset_id,
              text:
                res.data.results[key].asset_nickname +
                " (" +
                res.data.results[key].asset_name +
                ")",
            };
            self.asset_options.push(a);
          });
        });
      }
    },

    fetchUsers: function (search) {
      if (search.length > 2) {
        this.users = [{ value: null, text: "--Select User--" }];
        // Load User drop down
        let url =
          process.env.VUE_APP_BASEURL +
          "user/users/get-all-users/?query=true" +
          "&name=" +
          search;
        if (this.is_staff) {
          url = url + "&q_org_id=" + this.org_id;
        }

        //   if (this.organization != null) {
        //     if (this.organization == "system") {
        //       url = url + "&role_type=" + 0;
        //     } else {
        //       url = url + "&q_org_id=" + this.organization;
        //     }
        //   }
        const o_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: url,
        };
        var self = this;

        this.$http(o_options).then((res) => {
          res.data.map(function (value, key) {
            let user = {
              value: res.data[key].id,
              text: res.data[key].first_name,
            };
            self.users.push(user);
          });
        });
      }
    },
    // --------------previous asset list with search

    // fetchOptions(search, loading) {
    //   if (search.length) {
    //     loading(true);
    //     // Load assets here
    //     const a_options = {
    //       method: "GET",
    //       headers: { "content-type": "application/json" },
    //       url:
    //         process.env.VUE_APP_BASEURL +
    //         "asset/org-asset?org_id=" +
    //         this.org_id +
    //         "&name=" +
    //         search,
    //     };
    //     var self = this;
    //     this.$http(a_options).then((res) => {
    //       self.asset_options = [];
    //       res.data.results.map(function (value, key) {
    //         let a = {
    //           value: res.data.results[key].asset_id,
    //           text:
    //             res.data.results[key].asset_name +
    //             " (" +
    //             res.data.results[key].assetType.asset_type_name +
    //             ")",
    //         };
    //         self.asset_options.push(a);
    //         loading(false);
    //       });
    //     });
    //   }
    // },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
