<template>
  <div>
    <!-- {{ filter }} -->
    <validation-observer ref="ChangeAssetOwnerForm">
      <b-form class="auth-login-form mt-2" @submit.prevent="addChangeGroupType">
        <div class="col-12">
          <div v-if="!isCheckAllFilterResults">
            <span style="color: #d0c9d0; font-size: 0.857rem"
              >Asset Name :</span
            >
            <div class="w-100">
              <b-badge
                v-for="asset in asset_id"
                :key="asset"
                variant="light-primary"
                class="mb-1 mr-1"
              >
                {{ asset.asset_name }}
              </b-badge>
            </div>
          </div>
          <!-- <b-form-group
            label="Select Assets"
            label-for="Assets"
            v-if="!isCheckAllFilterResults"
          >
            <v-select
              multiple
              v-model="asset_id"
              label="text"
              :options="asset_options"
              @search="fetchAssetOptions"
              autocomplete
              :reduce="(asset_id) => asset_id.value"
            />
          </b-form-group> -->
          <div v-else class="w-100">
            <div v-for="f in filter" :key="f" class="d-flex">
              <!-- <b-badge variant="light-primary" v-if="f.group" class="mb-1">
                {{ f.group.text }}
              </b-badge> -->
              <span v-if="f.risk_level" class="mb-1">
                <!-- {{ f.risk_level.text }} -->
                <span v-for="r in f.risk_level" :key="r">
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 0"
                    >Unknown</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 1"
                    >Safe</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 2"
                    >Low</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 3"
                    >Medium</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 4"
                    >High</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 5"
                    >Critical</b-badge
                  >
                </span>
              </span>

              <b-badge
                variant="light-primary"
                v-if="f.asset_grouptype"
                class="mb-1"
              >
                {{ f.asset_grouptype }}
              </b-badge>
            </div>
          </div>
          <b-form-group label="Asset Exposure" label-for="grouptype">
            <template #label>
              <span>Asset Exposure</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="asset_type"
              rules="required"
            >
              <b-form-select
                class=""
                style="width: 100%"
                v-model="asset_group_type"
                :options="assetGroupTypes"
                :state="errors.length > 0 ? false : null"
              >
              </b-form-select>
            </validation-provider>
          </b-form-group>
          <b-form-group v-if="asset_group_type === 'internal'">
            <template #label>
              <span>Network Location</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
              <b-button
                class="ml-1"
                style="padding: 0.186rem 1rem !important"
                @click="gotoCreateNetwork()"
                variant="outline-success"
                size="sm"
                >+ Add Network Location</b-button
              >
            </template>
            <v-select
              v-model="networkLocation"
              label="text"
              placeholder="--Select Network Location--"
              :options="networkLocationOptions"
              autocomplete
              :reduce="(networkLocation) => networkLocation.value"
              :clearable="false"
            />
          </b-form-group>
        </div>
        <div class="row">
          <div class="col-12">
            <b-button
              type="submit"
              variant="primary"
              size="sm"
              :disabled="asset_group_type === '' || disable"
            >
              <span v-if="isLoading">
                <b-spinner type="border" small></b-spinner>
                Please wait
              </span>
              <span v-else>Update</span>
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
    <b-modal
      ref="modal-new-network-location"
      title="Create New Network Location"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <AddNetworkLocation
        :redirectNetwork="redirectNetwork"
        :closeSidebar="closeNetworkModal"
      />
    </b-modal>
  </div>
</template>
<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormSelect,
  BBadge,
  BModal,
  BSpinner
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import AddNetworkLocation from "../NetworkLocation/add.vue";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormSelect,
    BBadge,
    AddNetworkLocation,
    BModal,
    BSpinner
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      // asset_id: [],
      asset_options: [],
      assetGroupTypes: [
        { value: null, text: "--Select Asset Group Type--" },
        { value: "internal", text: "Internal" },
        { value: "external", text: "Internet Facing" },
      ],
      asset_group_type: "",
      organizations: [],
      networkLocationOptions: [],
      networkLocation: null,
      redirectNetwork: true,
      disable: false,
      isLoading: false,
    };
  },
  props: {
    asset_id: {
      type: Array,
      required: true,
    },
    isCheckAllFilterResults: {
      type: Boolean,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
    group_filter_options: {
      type: Array,
      required: true,
    },
    parentCallBack: Function,
  },
  watch: {
    asset_group_type: function (newAssetGroupType, oldAssetGroupType) {
      if (newAssetGroupType === "internal") {
        this.loadnetworkLocation();
      }
    },
  },
  methods: {
    load: function () {},
    loadnetworkLocation() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/network-location/",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.networkLocationOptions = res.data.map((item) => ({
          value: item.id,
          text: item.title,
        }));

        // Find the index of the item with text "DEFAULT"
        const defaultIndex = this.networkLocationOptions.findIndex(
          (item) => item.text === "DEFAULT"
        );

        // If "DEFAULT" is found, set it as the default selection
        if (defaultIndex !== -1) {
          this.networkLocation =
            this.networkLocationOptions[defaultIndex].value;
        } else if (this.networkLocationOptions.length > 0) {
          // If "DEFAULT" is not found, fallback to selecting the first item
          this.networkLocation = this.networkLocationOptions[0].value;
        }
      });
    },
    gotoCreateNetwork() {
      this.$refs["modal-new-network-location"].show();
    },
    closeNetworkModal() {
      this.$refs["modal-new-network-location"].hide();
      this.loadnetworkLocation();
    },

    addChangeGroupType: function () {
      this.$refs.ChangeAssetOwnerForm.validate().then((success) => {
        this.disable = true;
        this.isLoading = true;
        if (success) {
          let data = {
            // assets: this.asset_id,
            assets: this.asset_id.map((asset) => asset.asset_id),
            asset_grouptype: this.asset_group_type,
          };
          if (this.asset_group_type == "internal") {
            data.network_location = this.networkLocation;
          }
          if (this.isCheckAllFilterResults) {
            data.filters = this.filter.map((item) => {
              if (item.group && typeof item.group === "object") {
                return { group: item.group.value };
              }
              if (item.risk_level && typeof item.risk_level === "object") {
                return { risk_level: item.risk_level };
              }

              return item;
            });
          }
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url:
              process.env.VUE_APP_BASEURL +
              "asset/org-asset/update-asset-grouptype/",
          };
          var self = this;
          this.$http(options).then((res) => {
            if (res.data.status == "success") {
              this.$router.push({
                name: "Assets",
              });
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
            this.isLoading = false;
            this.disable = false;
            this.parentCallBack();
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
