<template>
  <b-card-code>
    <!-- Search Form -->
    <div class="row justify-content-end">
      <b-button
        @click="gotoaddAsset()"
        type="button"
        variant="outline-primary"
        class="mr-1"
        size="sm"
      >
        Add Asset
      </b-button>
      <router-link :to="'/assets/upload-nessus-report'">
        <b-button
          @click="addNessusReport()"
          type="button"
          variant="outline-primary"
          class="mr-3"
          size="sm"
        >
          Upload Vulnerability
        </b-button>
      </router-link>
      <!-- Upload Nessus Report -->
    </div>
    <b-tabs v-model="tabIndex">
      <b-tab title="Managed Assets">
        <b-card-code class="mb-0">
          <b-card-code class="bg-light-secondary mb-1">
            <div class="d-flex align-items-center mb-1">
              <h4 class="text-primary">Search</h4>
            </div>
            <b-form inline style="display: inline-block; width: 100%">
              <div class="row" style="padding: 5px">
                <div class="col-4" v-if="!is_staff">
                  <v-select
                    v-model="group_filter"
                    label="text"
                    class=""
                    @search="fetchGroup"
                    placeholder="--Search Group--"
                    :options="group_filter_options"
                    autocomplete
                    style="width: 100%"
                    :reduce="(group_filter) => group_filter.value"
                  />
                </div>
                <div class="col-4" v-if="!is_staff">
                  <v-select
                    v-model="risk_level"
                    multiple
                    label="text"
                    taggable
                    class="ml-1"
                    placeholder="--Select Risk--"
                    :options="risk_level_filter_options"
                    autocomplete
                    style="width: 100%"
                    :reduce="(risk_level) => risk_level.value"
                  />
                </div>
                <div class="col-4" v-if="is_staff">
                  <v-select
                    v-model="group_filter"
                    label="text"
                    class=""
                    placeholder="--Search Group--"
                    :options="group_filter_options"
                    autocomplete
                    @search="fetchGroup"
                    style="width: 100%"
                    :reduce="(group_filter) => group_filter.value"
                  />
                </div>
                <div class="col-4" v-if="is_staff">
                  <v-select
                    v-model="risk_level"
                    multiple
                    label="text"
                    taggable
                    class=""
                    placeholder="--Select Risk--"
                    :options="risk_level_filter_options"
                    autocomplete
                    style="width: 100%"
                    :reduce="(risk_level) => risk_level.value"
                  />
                </div>
                <div class="col-4">
                  <b-form-select
                    class="w-100"
                    v-model="asset_type"
                    @change="searchFn"
                    :options="asset_type_options"
                  >
                  </b-form-select>
                  <!-- style="color: #7367f0" -->
                </div>
              </div>

              <div class="row" style="padding: 5px">
                <div class="col-5">
                  <b-form-input
                    class="w-100"
                    id="name"
                    v-model="name_filter"
                    name="name"
                    placeholder="Search with Name or Nick Name"
                  />
                </div>
                <div class="col-4">
                  <b-form-select
                    class="w-100"
                    v-model="asset_grouptype"
                    @change="searchFn"
                    :options="asset_grouptype_options"
                  >
                  </b-form-select>
                </div>
                <div class="col-3 d-flex justify-content-end">
                  <b-button class="" variant="secondary" @click="refreshFn">
                    <feather-icon icon="RefreshCwIcon" class="mr-50" />Refresh
                  </b-button>
                  <b-button
                    class="ml-1"
                    variant="primary"
                    @click="searchFn"
                    :disabled="disable"
                  >
                    <span v-if="isloadingSearch"
                      ><b-spinner type="border" small></b-spinner> Please
                      wait</span
                    >
                    <span v-else>
                      <feather-icon
                        icon="SearchIcon"
                        class="mr-50"
                      />Search</span
                    >
                  </b-button>
                </div>
              </div>
            </b-form>
          </b-card-code>

          <b-form inline style="p0 margin-top: 30px; margin-bottom: 10px">
            <b-form-checkbox
              class="ml-1"
              name="selected-items"
              v-model="isCheckAll"
              label="Select All"
              @change="checkAll()"
            >
              Select all in this page
            </b-form-checkbox>

            <b-form-checkbox
              class="ml-1"
              name="selected-items"
              v-model="isCheckAllFilterResults"
              label="Select All"
              v-if="is_search_on"
            >
              Select all assets that matches this search
            </b-form-checkbox>
            <div class="clearfix ml-auto">
              <b-form-select v-model="selected_action" @change="doBulkAction">
                <template #first>
                  <option :value="null">--With Selected--</option>
                </template>
                <option value="delete">Delete</option>
                <option value="scan">Scan</option>
              </b-form-select>
            </div>
          </b-form>
        </b-card-code>
        <div class="row">
          <div class="col-12 d-flex justify-content-end mb-1">
            <b-button
              variant="outline-primary"
              size="sm"
              class="mr-1"
              @click="loadAssetOwnerChange()"
            >
              <span
                ><feather-icon icon="UserIcon" class="mr-50" />Change Asset
                Owner</span
              >
            </b-button>
            <b-button
              v-if="
                this.$store.state.app.user.permissions.includes(
                  'asset.list_all_org_assets'
                )
              "
              variant="outline-primary"
              size="sm"
              class="mr-1"
              @click="loadAssignAsset()"
            >
              <span
                ><feather-icon icon="UserCheckIcon" class="mr-50" />Assign
                Asset</span
              >
            </b-button>
            <b-button
              variant="outline-primary"
              size="sm"
              class="mr-1"
              @click="loadAssetGrouptype()"
            >
              <span
                ><feather-icon icon="GridIcon" class="mr-50" />Change Asset
                Exposure</span
              >
            </b-button>
            <b-button
              variant="outline-primary"
              size="sm"
              class="mr-1"
              @click="loadAssetGroup()"
            >
              <span
                ><feather-icon icon="PackageIcon" class="mr-50" />Add to
                Group</span
              >
            </b-button>
          </div>
        </div>
        <div class="d-flex justify-content-center mb-1" v-if="loading">
          <b-spinner class="float-right" label="Floated Right" />
        </div>
        <!-- Search Form ends here-->
        <div class="w-100 d-flex justify-content-between aligin-items-center">
          <div v-if="!loading" class="clearfix">
            Total of {{ total_assets_count }} Assets
          </div>
          <div
            v-if="!loading"
            class="mr-1 d-flex justify-content-end align-items-center mb-1"
            style="width: 35%"
          >
            <span class="mr-1">Per page</span>
            <b-form-select
              class=""
              size="sm"
              style="width: 30%"
              v-model="perPage"
              :options="page_filter_options"
              @change="searchFn()"
            >
            </b-form-select>
          </div>
        </div>
        <div v-if="!loading">
          <b-table
            responsive
            id="assetTable"
            :fields="computedFields"
            :items="items"
            :per-page="perPage"
            ref="table"
            v-if="items.length"
            hover
            style="min-height: 200px"
          >
            <template #cell(index)="data">
              {{ data.index + 1 + perPage * (currentPage - 1) }}
            </template>
            <template #cell(selected_assets)="data">
              <b-form-checkbox
                name="selected-items"
                v-model="selectedAssets"
                :value="{
                  asset_id: data.item.asset_id,
                  asset_name: data.item.asset_name,
                }"
                @change="updateCheckAll()"
              >
              </b-form-checkbox>
            </template>
            <template #cell(asset_name)="data">
              <a
                class="text-truncate"
                @click="redirectToAssetOverview(data.item.asset_id)"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                v-b-tooltip.hover.v-dark
                :title="data.item.asset_name"
              >
                <span>
                  {{
                    isExpanded(data.item.asset_id)
                      ? data.item.asset_name
                      : truncateName(data.item.asset_name)
                  }}
                </span>
              </a>
              <a
                v-if="data.item.asset_name.length > 30"
                @click="toggleExpand(data)"
                size="sm"
                variant="outline-primary"
              >
                <span style="font-size: 12px !important; font-weight: 600">{{
                  isExpanded(data.item.asset_id) ? " show less" : "..."
                }}</span></a
              >
            </template>
            <template #cell(risk_level)="data">
              <b-badge
                style="background-color: #991d28"
                v-if="data.item.risk_level == 5"
                >Critical</b-badge
              >
              <b-badge variant="danger" v-else-if="data.item.risk_level == 4"
                >High</b-badge
              >
              <b-badge variant="warning" v-else-if="data.item.risk_level == 3"
                >Medium</b-badge
              >
              <b-badge variant="primary" v-else-if="data.item.risk_level == 2"
                >Low</b-badge
              >
              <b-badge variant="success" v-else-if="data.item.risk_level == 1"
                >Safe</b-badge
              >
              <b-badge variant="secondary" v-else>Unknown</b-badge>
            </template>
            <template #cell(is_verified)="data">
              <b-avatar v-if="data.item.is_verified" variant="success">
                <feather-icon icon="ThumbsUpIcon" />
              </b-avatar>
              <b-avatar v-else variant="danger">
                <feather-icon icon="ThumbsDownIcon" />
              </b-avatar>
            </template>
            <template #cell(assetGroup)="data">
              <b-badge
                v-if="data.item.asset_grouptype == 'external'"
                variant="info"
                class="text-capitalize"
                >Internet Facing</b-badge
              >
              <b-badge v-else variant="primary" class="text-capitalize">{{
                data.item.asset_grouptype
              }}</b-badge>
            </template>

            <template #cell(actions)="data">
              <div class="" style="display: grid; justify-content: center">
                <b-button
                  v-if="
                    $store.state.app.user.permissions.includes('scan.add_scan')
                  "
                  variant="primary"
                  size="sm"
                  style="width: 106px"
                  @click="
                    openScannerSidebar(
                      data.item.asset_name,
                      data.item.asset_grouptype,
                      data.item.asset_id
                    )
                  "
                >
                  Scan now
                </b-button>
                <!-- <b-dropdown
              v-if="$store.state.app.user.permissions.includes('scan.add_scan')"
              size="sm"
              class="w-100"
              variant="primary"
            >
              <template v-slot:button-content>
                <span v-if="isloading && data.item.asset_id == asset_id"
                  ><b-spinner type="border" small></b-spinner> Please wait</span
                >
                <span v-else>Scan Now</span>
              </template>
              <b-dropdown-item
                v-for="item in agent_types"
                :key="item.agent_type_id"
                :disabled="item.is_active == false"
                v-b-tooltip.hover.v-dark
                :title="
                  item.is_active == false
                    ? 'Upgrade your Plan'
                    : item.agent_type_nicename
                "
                @click="
                  openCModal(
                    data.item.asset_id,
                    item.agent_type_id,
                    item.agent_type_code
                  )
                "
                >{{ item.agent_type_nicename }}</b-dropdown-item
              >
            </b-dropdown> -->
                <!-- <button type="button" class="btn ml-auto btn-primary btn-sm" v-for="item in data.item.agent_configurations" :key="item.agent_conf_id" @click="openCModal(item.agent_conf_id, item.agent.agent_meta)" >{{item.agent.agent_name}}</button> -->
                <b-dropdown
                  size="sm"
                  class="my-1 w-100"
                  variant="outline-primary"
                  text="Actions"
                >
                  <b-dropdown-item @click="scanAsset(data.item.asset_id)"
                    >Manage</b-dropdown-item
                  >
                  <b-dropdown-item
                    @click="
                      generateReport(
                        data.item.asset_id,
                        data.item.org_id,
                        data.item.org_ref_id,
                        data.item.asset_nickname
                      )
                    "
                    >Generate Report</b-dropdown-item
                  >
                  <b-dropdown-item @click="editAsset(data.item.asset_id)"
                    >Edit</b-dropdown-item
                  >
                  <b-dropdown-divider />
                  <b-dropdown-item
                    @click="
                      gotoDelete(data.item.asset_id, data.item.asset_name)
                    "
                    >Delete</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </template>
          </b-table>
          <div v-else>
            <div
              class="w-100 d-flex flex-column align-items-center justify-content-center py-4"
            >
              <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
              <h3 class="font-weight-bolder">No Data Found</h3>
              <p>
                Navigate to add new Asset ,
                <span
                  class="font-weight-bold text-success cursor-pointer"
                  @click="
                    $router.push({
                      path: '/assets/add',
                      query: { isAddAsset: true },
                    })
                  "
                  >Click here</span
                >
              </p>
            </div>
          </div>
        </div>

        <b-pagination-nav
          v-model="currentPage"
          :numberOfPages="total"
          :total-rows="rows"
          :link-gen="linkGen"
          :per-page="perPage"
          :align="pagination_pos"
          aria-controls="assetTable"
          use-router
        />
      </b-tab>
      <b-tab title="Unmanaged Assets">
        <UnmanagedAssets :tabIndex="tabIndex" />
      </b-tab>
    </b-tabs>
    <b-modal
      ref="modal-customize-meta-burp"
      title="Customise Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <p class="my-1">Customize your scan here.</p>
      <AddBurpScan
        :agent_type_id="agent_type_id"
        :asset_id="asset_id"
        :scan_input_meta="scan_input_meta"
        :callBack="afterScan"
      />
    </b-modal>
    <b-modal
      ref="modal-normal-scan"
      title="Scan Options"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <AddNormalScan
        :agent_type_id="agent_type_id"
        :asset_id="asset_id"
        :callBack="afterScan"
        :agent_type="agent_type"
      />
    </b-modal>
    <b-modal
      ref="modal-nessus"
      title="Customise Scan"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <!-- <p class="my-1">Select Nessus Policy.</p> -->
      <NessusScan
        :agent_type_id="agent_type_id"
        :asset_id="asset_id"
        :callBack="afterScan"
        :agent_type="agent_type"
      />
    </b-modal>
    <b-modal
      ref="modal-bulk-scan"
      title="Scan Assets"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <BulkScanner
        :assets="selectedAssets"
        :filter="filter"
        :isCheckAllFilterResults="isCheckAllFilterResults"
        :is_search_on="is_search_on"
        :parentCallBack="closeModal"
      />
    </b-modal>
    <b-modal
      ref="report_generator"
      title="Generate Report"
      hide-footer
      no-close-on-backdrop
      size="lg"
    >
      <ReportGenerator
        :report_type="parseInt(1)"
        :asset_id="selected_asset"
        :org_id="org_id"
        :org_ref_id="org_ref_id"
        :parentCallBack="closeModal"
      />
    </b-modal>
    <b-modal
      ref="modal-change-asset-owner"
      title="Change Asset Owner"
      hide-footer
      no-close-on-backdrop
      @hidden="closeModal"
      size="lg"
    >
      <OwnerChange
        :asset_id="selectedAssets"
        :parentCallBack="closeModal"
        :isCheckAllFilterResults="isCheckAllFilterResults"
        :filter="filter"
      />
    </b-modal>
    <b-modal
      ref="modal-change-grouptype"
      title="Change Group Type"
      hide-footer
      no-close-on-backdrop
      @hidden="closeModal"
      size="lg"
    >
      <GroupTypeChange
        :asset_id="selectedAssets"
        :parentCallBack="closeModal"
        :isCheckAllFilterResults="isCheckAllFilterResults"
        :filter="filter"
        :group_filter_options="group_filter_options"
      />
    </b-modal>

    <b-modal
      ref="modal-add-to-group"
      title="Add Assets to Group"
      hide-footer
      no-close-on-backdrop
      @hidden="closeModal"
      size="lg"
    >
      <AddAssetsToGroup
        :asset_id="selectedAssets"
        :parentCallBack="closeModal"
        :isCheckAllFilterResults="isCheckAllFilterResults"
        :filter="filter"
        :group_filter_options="group_filter_options"
      />
    </b-modal>
    <b-modal
      ref="modal-assign-asset"
      title="Assign Asset"
      hide-footer
      no-close-on-backdrop
      @hidden="closeModal"
      size="lg"
    >
      <AssignAsset
        :asset_id="selectedAssets"
        :parentCallBack="closeModal"
        :organization="organization"
        :isCheckAllFilterResults="isCheckAllFilterResults"
        :filter="filter"
      />
    </b-modal>
    <b-modal
      ref="modal-bulk-delete"
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      hide-footer
      no-close-on-backdrop
      @hidden="closeModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete the selected assets ?
      </b-card-text>
      <div style="margin-top: 10px">
        <span class="font-italic"
          >This action will remove the following associated records:
        </span>
        <ul class="font-italic">
          <li>Vulnerabilities</li>
          <li>Scans & Schedules</li>
          <li>Tasks</li>
        </ul>
      </div>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteAssetsAsBulk()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete
        <span class="text-primary">" {{ asset_name }} "</span> ?
      </b-card-text>
      <div style="margin-top: 10px">
        <span class="font-italic"
          >This action will remove the following associated records:
        </span>
        <ul class="font-italic">
          <li>Vulnerabilities</li>
          <li>Scans & Schedules</li>
          <li>Tasks</li>
        </ul>
      </div>

      <div class="d-flex justify-content-end">
        <b-button @click="deleteAsset()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>

    <b-sidebar
      id="sidebar-agenttype-list"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="600px"
    >
      <div class="mb-2">
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="closeSidebar()"
        />
      </div>
      <b-table
        responsive
        id="confTable"
        :fields="scan_fields"
        :items="filteredAgentTypes"
        hover
      >
        <template #cell(actions)="data">
          <b-button
            size="sm"
            variant="primary"
            @click="
              openCModal(
                data.item.agent_type_id,
                data.item.agent_type_code,
                assetIdSidebar
              )
            "
            type="button"
            class="btn btn-primary mr-1"
            style="position: relative"
            :disabled="data.item.is_active == false"
          >
            <span v-if="isloading && data.item.agent_type_id"
              ><b-spinner type="border" small></b-spinner> Please wait</span
            >
            <span v-else>Scan Now</span>
          </b-button>
        </template>
      </b-table>
    </b-sidebar>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import ReportGenerator from "../common/components/ReportGenerator.vue";
import BulkScanner from "./components/BulkScanner.vue";
import AddNormalScan from "./components/AddNormalScan.vue";
import NessusScan from "./components/NessusScanner.vue";
import AddBurpScan from "./components/AddBurpScan.vue";
import OwnerChange from "./changeAssetOwner.vue";
import AssignAsset from "./components/AssignAsset.vue";
import GroupTypeChange from "./changeGroupType.vue";
import AddAssetsToGroup from "./addAssetsToGroup.vue";
import UnmanagedAssets from "./components/unmanagedAssets/unManagedAssets.vue";
import vSelect from "vue-select";
import {
  BModal,
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBadge,
  BFormSelect,
  BFormGroup,
  BForm,
  BFormInput,
  BFormCheckbox,
  BSpinner,
  BSidebar,
  BAvatar,
  VBTooltip,
  BTab,
  BTabs,
} from "bootstrap-vue";
import AddScan from "./components/AddScan.vue";
import AssetMapping from "./components/AssetMapping.vue";

import RemoveMapping from "./components/RemoveAssetMapping.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BModal,
    AddScan,
    BFormInput,
    BFormSelect,
    BFormGroup,
    BForm,
    BFormCheckbox,
    BSpinner,
    ReportGenerator,
    BulkScanner,
    AddNormalScan,
    AddBurpScan,
    NessusScan,
    vSelect,
    OwnerChange,
    BSidebar,
    AssetMapping,
    RemoveMapping,
    AssignAsset,
    GroupTypeChange,
    AddAssetsToGroup,
    BAvatar,
    BTab,
    BTabs,
    UnmanagedAssets,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  // props: {
  //   asset_type: {
  //     type: String,
  //     required: true,
  //   },
  // },
  computed: {
    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      if (!this.is_staff)
        return this.fields.filter((field) => !field.requiresAdmin);
      // If the user IS an admin, return all fields.
      else return this.fields;
    },
    filteredAgentTypes() {
      return this.agent_types
        .map((item) => {
          if (
            !this.isIP(this.assetNameSidebar) &&
            this.assetTypeSidebar === "internal"
          ) {
            // Hide specific columns based on conditions
            if (
              ["attack_surface", "dsscan", "ipreputation", "ssllabs"].includes(
                item.agent_type_code
              )
            ) {
              return { ...item, isVisible: false };
            }
          } else if (
            this.isIP(this.assetNameSidebar) &&
            this.assetTypeSidebar === "internal"
          ) {
            // Hide specific columns based on conditions
            if (
              ["attack_surface", "dsscan", "ipreputation", "ssllabs"].includes(
                item.agent_type_code
              )
            ) {
              return { ...item, isVisible: false };
            }
          } else if (
            this.isIP(this.assetNameSidebar) &&
            this.assetTypeSidebar === "external"
          ) {
            // Hide specific columns based on conditions
            if (["dsscan", "ssllabs"].includes(item.agent_type_code)) {
              return { ...item, isVisible: false };
            }
          }
          // If none of the conditions match, show the column
          return { ...item, isVisible: true };
        })
        .filter((item) => item.isVisible);
    },
  },
  data() {
    return {
      userId: localStorage.getItem("userid"),
      selected_asset: 0,
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "selected_assets", label: "select" },
        { key: "asset_name", label: "Target" },
        { key: "risk_level", label: "Risk Level" },
        { key: "asset_nickname", label: "Name" },
        //{ key: 'assetType.asset_type_name', label: 'Asset Type' },
        { key: "is_verified", label: "Verified" },
        { key: "assetGroup", label: "Asset Exposure" },
        { key: "network_location_name", label: "Network Location" },
        {
          key: "org_name",
          label: "Organization",
          requiresAdmin: true,
        },
        { key: "actions", label: "Actions" },
      ],
      // Scanner attachment modal
      agent_types: [],
      agent_type: null,
      agent_type_id: 0,
      asset_id: 0,
      scan_input_meta: "",
      name_filter: "",
      is_staff: this.$store.state.app.user.is_staff,
      // token:this.$store.state.app.token, //test
      organization: this.$store.state.app.org_id,
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      // organization: null,
      org_id: null,
      org_ref_id: "",
      organizations: [],
      risk_level: null,
      risk_level_filter_options: [
        { value: 0, text: "Unknown" },
        { value: 1, text: "Safe" },
        { value: 2, text: "Low" },
        { value: 3, text: "Medium" },
        { value: 4, text: "High" },
        { value: 5, text: "Critical" },
      ],
      asset_type: null,
      asset_type_options: [
        { value: null, text: "--Select Asset Type--" },
        // { value: 0, text: "All Assets" },
        // { value: 1, text: "Network Assets" },
        // { value: 2, text: "Web Assets" },
        // { value: 3, text: "API" },
        // { value: 4, text: "Mobile" },
      ],
      asset_grouptype: null,
      asset_grouptype_options: [
        { value: null, text: "--Select Exposure--" },
        { value: "internal", text: "Internal" },
        { value: "external", text: "Internet Facing" },
      ],
      //For MULTI GROUPING
      selectedAssets: [],
      // with_selected_options: [
      //   { value: null, text: "--With Selected--" },
      //   { value: "delete", text: "Delete" },
      //   { value: "scan", text: "Scan" },
      //   { value: "changeassetowner", text: "Change Asset Owner" },
      //   // { value: "add_to_group", text: "Add to Group" },
      //   { value: "changegrouptype", text: "Change Group Type" },
      // ],
      selected_action: null,
      isCheckAllFilterResults: false,
      isCheckAll: false,
      filter: [],
      is_search_on: false,
      loading: false,
      total_assets_count: 0,
      group_filter: null,
      group_filter_options: [],
      isloading: "",
      asset_name: "",
      asset_name_id: "",
      openDeleteModal: false,
      isloadingSearch: false,

      currentGroupType: this.$route.query.grouptype,
      currentAssetType: this.$route.query.assettype,
      currentRiskLevel: this.$route.query.risk_level,
      currentAssetGroupType: this.$route.query.assetgrouptype,
      currentgroupId: this.$route.query.group_id,
      page_filter_options: [
        { value: "10", text: "10" },
        { value: "20", text: "20" },
        { value: "50", text: "50" },
        { value: "100", text: "100" },
        { value: "500", text: "500" },
      ],
      expandedItems: [],
      disable: false,
      assetNameSidebar: "",
      assetTypeSidebar: "",
      scan_fields: [
        { key: "agent_type_nicename", label: "Name" },
        { key: "actions", label: "Actions" },
      ],
      assetIdSidebar: null,
      tabIndex: 0,
    };
  },
  watch: {
    perPage(newVal, oldVal) {
      this.$router.replace({ query: { page: 1 } });
      this.currentPage = 1; // Reset currentPage to 1 when perPage changes
      this.searchFn();
    },
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
  },
  mounted() {
    this.onVerticalCollapse();
  },
  created: function () {
    this.setTabIndexFromPathQuery();
    const queryRiskLevel = this.$route.query.risk_level;
    if (queryRiskLevel) {
      const riskLevelValues = Array.isArray(queryRiskLevel)
        ? queryRiskLevel.map(Number)
        : [Number(queryRiskLevel)];
      this.risk_level = this.risk_level_filter_options
        .filter((option) => riskLevelValues.includes(option.value))
        .map((option) => option.value);
    }

    this.load();
    if (this.$route.query.group_id) {
      this.loadGroupsAndSearch();
    }
    this.searchFn();
    this.$router.replace({ query: null });
    // this.$refs.table.refresh();
  },
  methods: {
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    setTabIndexFromPathQuery() {
      console.log("Tab Index:", this.$route.query.tabIndex);
      if (this.$route.query.tabIndex && this.$route.query.tabIndex != null) {
        this.tabIndex = Number(this.$route.query.tabIndex);
        // this.$router.replace({ query: null });
      }
    },
    toggleExpand(data) {
      const index = this.expandedItems.indexOf(data.item.asset_id);
      if (index === -1) {
        this.expandedItems.push(data.item.asset_id);
      } else {
        this.expandedItems.splice(index, 1);
      }
    },
    isExpanded(itemId) {
      return this.expandedItems.includes(itemId);
    },
    truncateName(name) {
      if (name.length > 30) {
        return name.slice(0, 30);
      }
      return name;
    },

    isIP(value) {
      // Regular expression to match IP address format
      const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
      return ipRegex.test(value);
    },
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    loadAgentTypes: function () {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/type",
      };
      var self = this;
      this.$http(a_options).then((res) => {
        this.agent_types = res.data;
        this.agent_types = this.agent_types.filter(
          (item) =>
            item.agent_type_code !== "amass" &&
            item.agent_type_code !== "emailharvester" &&
            item.agent_type_code !== "hibp" &&
            item.agent_type_code !== "cidr" &&
            item.agent_type_code !== "prowler" &&
            item.agent_type_code != "cloud_discovery"
        );
      });
    },
    openScannerSidebar(asset_name, asset_grouptype, asset_id) {
      this.assetNameSidebar = asset_name;
      this.assetTypeSidebar = asset_grouptype;
      this.assetIdSidebar = asset_id;

      // Emit event to toggle sidebar visibility
      this.$root.$emit("bv::toggle::collapse", "sidebar-agenttype-list");
    },

    load: function () {
      this.onVerticalCollapse();
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      this.loading = true;

      //  asset type options
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/asset-type",
      };
      var self = this;
      this.$http(o_options).then((res) => {
        this.asset_type_name = [];
        res.data.map(function (value, key) {
          let at = {
            value: res.data[key].asset_type_id,
            text: res.data[key].asset_type_name,
          };

          self.asset_type_options.push(at);
        });
      });
    },
    // scan function
    doScanNow: function () {
      this.isloading = true;
      let data = {
        agent_type: this.agent_type_id,
        asset_id: this.asset_id,
        scan_created_by: this.userId,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/scan/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data.errors) {
          this.load();
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.errors,
              // title: "Successfully added the scan request",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        } else {
          this.load();
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully added the scan request",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
        this.isloading = false;
        this.searchFn();
      });
    },
    openCModal: function (id, type, assetIdSidebar) {
      this.agent_type = type;
      this.agent_type_id = id;
      this.asset_id = assetIdSidebar;
      if (type == "masscan") this.$refs["modal-normal-scan"].show();
      if (type == "burpsuit") this.$refs["modal-customize-meta-burp"].show();
      if (type == "nessus") this.$refs["modal-nessus"].show();
      if (type == "wpscan") this.$refs["modal-normal-scan"].show();
      if (type == "dsscan") this.doScanNow();
      if (type == "ipreputation") this.doScanNow();
      if (type == "ssllabs") this.doScanNow();
      if (type == "zap") this.$refs["modal-normal-scan"].show();
      // if (type == "attack_surface") this.$refs["modal-normal-scan"].show();
      if (type == "attack_surface") this.performAttackSurface();
      // } else {
      //   this.$refs["modal-normal-scan"].show();
      // }
    },
    afterScan: function (callBackParams) {
      this.$refs["modal-customize-meta-burp"].hide();
      this.$refs["modal-normal-scan"].hide();
      // this.$refs["modal-nessus"].hide();
      this.$refs[callBackParams].hide();
      this.searchFn();
      // this.load();
    },
    addAsset() {
      this.$router.push({ name: "Add Asset" });
    },
    editAsset(id) {
      this.$router.push({ name: "Edit Asset", params: { id: id } });
    },
    scanAsset(id) {
      this.$router.push({ name: "Scan Asset", params: { id: id } });
    },
    performAttackSurface: function () {
      this.isloading = true;
      this.disable = true;
      //call to axios
      var self = this;
      let data = {
        target: this.assetNameSidebar,
      };
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },
        data: data,
        url: process.env.VUE_APP_BASEURL + "scan/asm-scan/",
      };
      this.$http(options)
        .then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.isloading = false;
            this.disable = false;
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
            this.isloading = false;
            this.disable = false;
          }
        })
        .catch((err) => {
          this.isloading = false;
          this.disable = false;
        });
    },
    gotoDelete(asset_id, asset_name) {
      this.openDeleteModal = true;
      this.asset_name_id = asset_id;
      this.asset_name = asset_name;
    },
    deleteAsset() {
      // this.asset_name = asset_name;
      // if (confirm("Do you really want to delete " + this.asset_name + "?")) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/org-asset/" +
          this.asset_name_id +
          "/",
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.openDeleteModal = false;
        this.selectedAssets = [];
        this.searchFn();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
      // }
    },
    addNessusReport() {
      this.$router.push({ name: "Upload Nessus Report" });
    },
    gotoaddAsset() {
      this.$router.push({ path: "/assets/add", query: { isAddAsset: true } });
    },
    //  changeAssetOwner() {
    //   this.$router.push({ name: "Change Asset Owner" });
    // },
    searchFn: function (reset = true) {
      this.onVerticalCollapse();
      this.isloadingSearch = true;
      this.is_search_on = false;
      this.disable = true;
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "asset/asset?query=true" +
        "&page=" +
        this.currentPage +
        "&items_per_page=" +
        this.perPage;

      if (this.asset_type != null) {
        this.filter.push({ asset_type: this.asset_type });
        url = url + "&asset_type=" + this.asset_type;
      }
      if (this.$route.query.expiry_certificate) {
        url = url + "&expiry_certificate=1";
      }
      if (this.$route.query.risk_level && this.risk_level == null) {
        const queryString = this.$route.query.risk_level
          .map((item) => `risk_level=${item}`)
          .join("&");
        url = url + `&${queryString}`;
        this.risk_level = this.$route.query.risk_level; // Set selected filter values
        this.filter.push({ risk_level: this.risk_level });
      }
      if (this.$route.query.assettype && this.asset_type === null) {
        url = url + "&asset_type=" + this.currentAssetType;
        this.asset_type = this.$route.query.assettype;
        this.filter.push({ asset_type: this.asset_type });
      }
      if (this.$route.query.grouptype && this.asset_grouptype === null) {
        url = url + "&asset_grouptype=" + this.currentGroupType;
        this.asset_grouptype = this.$route.query.grouptype;
        this.filter.push({ asset_grouptype: this.asset_grouptype });
      }
      // if (this.$route.query.group_id && this.group_filter === null) {
      //   // const groupId = this.$route.query.group_id;
      //   url = url + "&group=" + this.currentgroupId;
      //   // url = url + `&group=${groupId}`;
      //   this.group_filter = this.currentgroupId;
      //   this.filter.push({ group: this.currentgroupId });
      // }

      if (this.$route.query.group_id && !this.group_filter) {
        const groupId = this.$route.query.group_id;
        url = url + `&group=${groupId}`;
        this.group_filter = groupId;
        this.filter.push({ group: groupId });
      }

      // ------------------------<

      if (this.organization != null) {
        // this.filter.push({ organization: this.organization });
        url = url + "&org_id=" + this.organization;
      }
      if (this.group_filter != null && !this.$route.query.group_id) {
        this.filter.push({ group: this.group_filter });
        url = url + "&group=" + this.group_filter;
      }
      if (this.name_filter != null && this.name_filter != "") {
        this.filter.push({ name: this.name_filter });
        url = url + "&name=" + this.name_filter;
      }
      if (this.risk_level != null) {
        // this.filter.push({ risk_level: this.risk_level });
        // url = url + "&risk_level=" + this.risk_level;

        const queryString = this.risk_level
          .map((item) => `risk_level=${item}`)
          .join("&");
        url = url + `&${queryString}`;
        this.filter.push({ risk_level: this.risk_level });
      }
      if (this.asset_grouptype != null && !this.$route.query.grouptype) {
        this.filter.push({ asset_grouptype: this.asset_grouptype });
        url = url + "&asset_grouptype=" + this.asset_grouptype;
      }
      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        console.log(res.data);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.total_assets_count = res.data.count;
        this.updateCheckAll();
        this.loading = false;
        this.disable = false;
        this.isloadingSearch = false;
        this.loadAgentTypes();
        //   this.currentPage = 1
        //   this.perPage = 10
        //   this.rows = res.data.count
      });
    },

    checkAll: function () {
      if (this.isCheckAll) {
        this.items.forEach((item) => {
          // const index = this.selectedAssets.indexOf(item.asset_id);
          // if (index < 0) this.selectedAssets.push(item.asset_id);
          const index = this.selectedAssets.findIndex(
            (asset) => asset.asset_id === item.asset_id
          );
          if (index < 0) {
            this.selectedAssets.push({
              asset_id: item.asset_id,
              asset_name: item.asset_name,
            });
          }
        });
      } else {
        this.items.forEach((item) => {
          const index = this.selectedAssets.findIndex(
            (asset) => asset.asset_id === item.asset_id
          );
          if (index >= 0) {
            this.selectedAssets.splice(index, 1);
          }
        });
        // this.items.forEach((item) => {
        //   const index = this.selectedAssets.indexOf(item.asset_id);
        //   this.selectedAssets.splice(index, 1);

        // });
      }
    },
    updateCheckAll: function () {
      var if_all = 0;
      this.items.forEach((item) => {
        console.log("item", item.asset_id);
        const index = this.selectedAssets.indexOf(item.asset_id);
        if (index >= 0) {
          console.log(index);
          if_all++;
        }
      });
      if (if_all == this.items.length) {
        this.isCheckAll = true;
      } else {
        this.isCheckAll = false;
      }
    },
    doBulkAction: function () {
      if (!this.selectedAssets.length && !this.isCheckAllFilterResults) {
        alert("Please select some items");
        this.selected_action = null;
      }
      switch (this.selected_action) {
        case "delete":
          // if (
          //   confirm(
          //     "Do you really want to delete the selected assets? Deletion of this asset deletes the Tasks, Vulnerabilities , Scans &Schedules associated with it"
          //   )
          // ) {
          //   this.deleteAssetsAsBulk();
          // }
          this.loadBulkDelete();
          this.selected_action = null;
          break;
        case "scan":
          // Open Bulk Scan Modal
          this.loadBulkScanner();
          this.selected_action = null;
          break;
        case "changeassetowner":
          this.loadAssetOwnerChange();
          // this.loadBulkScanner();
          // this.selected_action = null;
          break;
        case "assignasset":
          this.loadAssignAsset();
          break;
        case "changegrouptype":
          this.loadAssetGrouptype();
          break;
        case "addtogroup":
          this.loadAssetGroup();
          break;
        default:
          break;
      }
    },
    loadAssetOwnerChange: function () {
      if (!this.selectedAssets.length && !this.isCheckAllFilterResults) {
        alert("Please select some items");
        this.selected_action = null;
      } else {
        this.$refs["modal-change-asset-owner"].show();
      }
    },
    loadAssignAsset: function () {
      if (!this.selectedAssets.length && !this.isCheckAllFilterResults) {
        alert("Please select some items");
        this.selected_action = null;
      } else {
        this.$refs["modal-assign-asset"].show();
      }
    },
    loadAssetGrouptype: function () {
      if (!this.selectedAssets.length && !this.isCheckAllFilterResults) {
        alert("Please select some items");
        this.selected_action = null;
      } else {
        this.$refs["modal-change-grouptype"].show();
      }
    },
    loadAssetGroup: function () {
      if (!this.selectedAssets.length && !this.isCheckAllFilterResults) {
        alert("Please select some items");
        this.selected_action = null;
      } else {
        this.$refs["modal-add-to-group"].show();
      }
    },
    loadBulkDelete: function () {
      this.$refs["modal-bulk-delete"].show();
    },
    loadBulkScanner: function () {
      let data = {
        // assets: this.selectedAssets,
        assets: this.selectedAssets.map((asset) => asset.asset_id),
      };
      if (this.asset_type != 0)
        this.filter.push({ asset_type_id: this.asset_type });
      if (this.isCheckAllFilterResults && this.is_search_on) {
        data.filters = this.filter;
      }
      this.$refs["modal-bulk-scan"].show();
      this.selected_action = null;
    },
    deleteAssetsAsBulk: function () {
      this.loading = true;
      var self = this;
      let data = {
        // assets: this.selectedAssets,
        assets: this.selectedAssets.map((asset) => asset.asset_id),
      };
      if (this.asset_type != 0)
        this.filter.push({ asset_type_id: this.asset_type });
      if (this.isCheckAllFilterResults && this.is_search_on) {
        data.filters = this.filter;
      }
      this.$http({
        method: "DELETE",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "asset/org-asset/bulk-delete/",
      }).then((res) => {
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.selectedAssets = [];
          self.searchFn(false);
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        self.loading = false;
      });
      this.closeModal();
    },
    generateReport(id, org_id, org_ref_id) {
      this.selected_asset = id;
      this.org_id = org_id;
      this.org_ref_id = org_ref_id;

      this.$refs["report_generator"].show();
    },
    closeModal() {
      this.$refs["report_generator"].hide();
      this.$refs["modal-bulk-scan"].hide();
      this.$refs["modal-change-asset-owner"].hide();
      this.$refs["modal-assign-asset"].hide();
      this.$refs["modal-change-grouptype"].hide();
      this.$refs["modal-bulk-delete"].hide();
      this.$refs["modal-add-to-group"].hide();
      this.searchFn();
      // this.loadGroupsAndSearch();
      this.selected_action = null;
      this.isCheckAllFilterResults = false;
      this.isCheckAll = false;
      this.checkAll();
      this.selectedAssets = [];
    },
    refreshFn() {
      this.group_filter = null;
      this.risk_level = null;
      this.asset_type = null;
      this.name_filter = "";
      (this.asset_grouptype = null), this.searchFn();
    },
    loadGroupsAndSearch: function () {
      this.group_filter_options = [];
      this.group_filter = null;

      if (this.organization == null) {
        this.filter = [];
        this.is_search_on = false;
        this.items = [];
        return;
      }
      if (!this.is_staff) {
        this.loading = true;
        this.organization = this.$store.state.app.org_id;
      }
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "asset/group/list-all-asset-groups?org_id=" +
          this.organization,
      };
      var self = this;
      this.$http(a_options).then((res) => {
        res.data.map(function (value, key) {
          let a = {
            value: res.data[key].group_id,
            text: res.data[key].group_name,
          };
          self.group_filter_options.push(a);
        });
        const queryGroup = this.$route.query.group_id;
        if (queryGroup) {
          const groupValues = Array.isArray(queryGroup)
            ? queryGroup.map(Number)
            : [Number(queryGroup)];
          this.group_filter = this.group_filter_options
            .filter((option) => groupValues.includes(option.value))
            .map((option) => option.value);
        }
      });
      //Then search

      // Load Groups

      // this.searchFn();
    },

    fetchGroup: function (search) {
      if (search.length > 0) {
        this.users = [];

        if (this.organization == null) {
          this.filter = [];
          this.is_search_on = false;
          this.items = [];
          return;
        }

        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/group/list-all-asset-groups?org_id=" +
            this.organization +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          this.group_filter_options = [];
          res.data.map(function (value, key) {
            let a = {
              value: res.data[key].group_id,
              text: res.data[key].group_name,
            };
            self.group_filter_options.push(a);
          });
          // const queryGroup = this.$route.query.group_id;
          // if (queryGroup) {
          //   const groupValues = Array.isArray(queryGroup)
          //     ? queryGroup.map(Number)
          //     : [Number(queryGroup)];
          //   this.group_filter = this.group_filter_options
          //     .filter((option) => groupValues.includes(option.value))
          //     .map((option) => option.value);
          // }
        });
      }
    },

    showRemoveSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-remove-assign-handler");
    },
    closeRemoveSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-remove-assign-handler");
      // this.load();
    },
    showSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-assign-handler");
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-assign-handler");
      this.$root.$emit("bv::toggle::collapse", "sidebar-agenttype-list");
      // this.load();
    },
    redirectToAssetOverview(asset_id) {
      const assetId = asset_id;
      this.$router.push(`/assets/scan/${assetId}`);
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
