<template>
  <div>
    <!-- {{ filter }} -->
    <validation-observer ref="ChangeAssetOwnerForm" >
      <b-form class="auth-login-form mt-2" @submit.prevent="addAssetsToGroup">
        <div class="col-12">
          <div v-if="!isCheckAllFilterResults">
            <span style="color: #5e5873; font-size: 0.857rem"
              >Asset Name :</span
            >
            <div class="w-100">
              <b-badge
                v-for="asset in asset_id"
                :key="asset"
                variant="light-primary"
                class="mb-1 mr-1"
              >
                {{ asset.asset_name }}
              </b-badge>
            </div>
          </div>
          <!-- <b-form-group
            label="Select Assets"
            label-for="Assets"
            v-if="!isCheckAllFilterResults"
          >
            <v-select
              multiple
              v-model="asset_id"
              label="text"
              :options="asset_options"
              @search="fetchAssetOptions"
              autocomplete
              :reduce="(asset_id) => asset_id.value"
            />
          </b-form-group> -->
          <div v-else class="w-100">
            <div v-for="f in filter" :key="f" class="d-flex">
              <!-- <b-badge variant="light-primary" v-if="f.group" class="mb-1">
                {{ f.group.text }}
              </b-badge> -->
              <span v-if="f.risk_level" class="mb-1">
                <!-- {{ f.risk_level.text }} -->
                <span v-for="r in f.risk_level" :key="r">
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 0"
                    >Unknown</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 1"
                    >Safe</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 2"
                    >Low</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 3"
                    >Medium</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 4"
                    >High</b-badge
                  >
                  <b-badge variant="light-primary" class="mr-1" v-if="r == 5"
                    >Critical</b-badge
                  >
                </span>
              </span>

              <b-badge
                variant="light-primary"
                v-if="f.asset_grouptype"
                class="mb-1"
              >
                {{ f.asset_grouptype }}
              </b-badge>
            </div>
          </div>
          <b-form-group label="Asset Group :" label-for="asset group">
            <template #label>
              <span>Asset Group</span>
              <span
                class="text-danger font-weight-bold h5"
                style="margin-left: 3px"
                >*</span
              >
              <b-button
                class="ml-1"
                style="padding: 0.186rem 1rem !important"
                @click="gotoCreateGroup()"
                variant="outline-success"
                size="sm"
                >+ Add Asset Group</b-button
              >
            </template>
            <validation-provider
              #default="{ errors }"
              name="Asset Group"
              rules="required"
            >
              <v-select
                v-model="group_filter"
                label="text"
                class=""
                @search="fetchGroup"
                placeholder="--Search Group--"
                :options="group_filter_options"
                autocomplete
                style="width: 100%"
                :state="errors.length > 0 ? false : null"
                :reduce="(group_filter) => group_filter.value"
              />
            </validation-provider>
          </b-form-group>
          <!-- {{ group_filter }} -->
        </div>
        <div class="row">
          <div class="col-12">
            <b-button
              type="submit"
              variant="primary"
              size="sm"
              :disabled="group_filter === null || disable"
            >
              <span v-if="isLoading"
                ><b-spinner type="border" small></b-spinner> Please wait</span
              >
              <span v-else>Update</span>
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
    <b-modal
      ref="modal-new-group"
      title="Create New Group"
      hide-footer
      size="lg"
    >
      <AddAssetGroup
        :is_asset_group="is_asset_group"
        :parentCallBack="closeModal"
      />
    </b-modal>
  </div>
</template>
<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormSelect,
  BBadge,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import AddAssetGroup from "../AssetGroup/add.vue";
export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormSelect,
    BBadge,
    AddAssetGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      // asset_id: [],
      asset_options: [],
      group_filter: null,
      group_filter_options: [],
      organizations: [],
      is_asset_group: true,
      disable: false,
      isLoading: false,
    };
  },
  props: {
    asset_id: {
      type: Array,
      required: true,
    },
    isCheckAllFilterResults: {
      type: Boolean,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
    group_filter_options: {
      type: Array,
      required: true,
    },
    parentCallBack: Function,
  },
  created: function () {
    // this.fetchAssetOptions();
    //  this.load(); --> not calling this due page load issues
  },
  methods: {
    load: function () {},
    fetchGroup: function (search) {
      if (search.length > 2) {
        this.users = [];

        if (this.organization == null) {
          this.filter = [];
          this.is_search_on = false;
          this.items = [];
          return;
        }

        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/group/list-all-asset-groups?org_id=" +
            this.organization +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          this.group_filter_options = [];
          res.data.map(function (value, key) {
            let a = {
              value: res.data[key].group_id,
              text: res.data[key].group_name,
            };
            self.group_filter_options.push(a);
          });
        });
      }
    },
    addAssetsToGroup: function () {
      this.$refs.ChangeAssetOwnerForm.validate().then((success) => {
        this.disable = true;
        this.isLoading = true;
        if (success) {
          let data = {
            // assets: this.asset_id,
            assets: this.asset_id.map((asset) => asset.asset_id),
            group_id: this.group_filter,
          };
          if (this.isCheckAllFilterResults) {
            data.filters = this.filter.map((item) => {
              if (item.group && typeof item.group === "object") {
                return { group: item.group.value };
              }
              if (item.risk_level && typeof item.risk_level === "object") {
                return { risk_level: item.risk_level };
              }

              return item;
            });

            // data.filters = this.filter;
          }
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: data,
            url: process.env.VUE_APP_BASEURL + "asset/group/bulk-add-assets/",
          };
          var self = this;
          this.$http(options).then((res) => {
            if (res.data.status == "success") {
              this.$router.push({
                name: "Assets",
              });
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
            this.isLoading = false;
            this.disable = false;
            this.parentCallBack();
          });
        }
      });
    },
    gotoCreateGroup: function () {
      this.$refs["modal-new-group"].show();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
